import React, { useContext, useEffect, useState, useMemo } from 'react';
import { GlobalContext } from '../../../context/globalState';

function NumberInput({
  defaultValue,
  onPlanChange,
  plan,
  planIndex,
  detailIndex,
  id = '',
  detailInnerIndex = -2,
}) {
  const { allPlanFeatures, pricingTable } = useContext(GlobalContext);
  const [disabledValue, setDisabledValue] = useState(1);

  const uniqueId = useMemo(() => {
    if (pricingTable?.length) {
      try {
        const ref = JSON.parse(pricingTable)?.pricing_plans?.[plan]?.plan_id;
        let ref2 = allPlanFeatures[planIndex].details[detailIndex]?.detail_id;
        if (detailInnerIndex > -1) {
          ref2 =
            allPlanFeatures[planIndex].details[detailIndex]?.details[
              detailInnerIndex
            ]?.detail_id || ref2;
        }
        return `${ref}-${ref2}`;
      } catch (err) {
        return id;
      }
    }
  }, [pricingTable]);

  useEffect(() => {
    let baseDetail = allPlanFeatures[planIndex].details[detailIndex];
    if (detailInnerIndex > -1) {
      baseDetail =
        allPlanFeatures[planIndex].details[detailIndex]?.details[
          detailInnerIndex
        ] || baseDetail;
    }
    setDisabledValue(baseDetail[`plan_${plan + 1}_value`]);
  }, [allPlanFeatures, plan, planIndex, detailIndex, detailInnerIndex]);
  const [number, setNumber] = useState(parseInt(defaultValue, 10));
  const decrement = () => {
    if (number > disabledValue) {
      setNumber(number - 1);
      onPlanChange(plan, planIndex, detailIndex, number - 1, detailInnerIndex);
    }
  };
  const increment = () => {
    setNumber(number + 1);
    onPlanChange(plan, planIndex, detailIndex, number + 1, detailInnerIndex);
  };
  useEffect(() => {
    setNumber(parseInt(defaultValue, 10));
  }, [defaultValue]);
  return (
    <div className="flex items-center">
      <button
        id={`${uniqueId}-minus`}
        onClick={decrement}
        className={`mr-2 ${
          number <= disabledValue
            ? 'text-gray-300 cursor-not-allowed dark:text-gray-600'
            : 'text-gray-400 dark:text-gray-400 hover:text-gray-500 dark:hover:text-gray-300'
        }`}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 12H9.5M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <p
        id={`${uniqueId}-number`}
        className="font-inter-hero min-w-[16px] text-sm leading-5 font-semibold text-gray-600 dark:text-gray-400 inline-flex items-center justify-center"
      >
        {number}
      </p>
      <button
        id={`${uniqueId}-plus`}
        onClick={increment}
        className="text-gray-400 dark:text-gray-400 ml-2 hover:text-gray-500 dark:hover:text-gray-300"
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5 9V12M12.5 12V15M12.5 12H15.5M12.5 12H9.5M21.5 12C21.5 16.9706 17.4706 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
export default NumberInput;
