import React, { useEffect, useState, useMemo, useContext } from 'react';
import { GlobalContext } from '../../../context/globalState';

export const isValueTrue = (value = '') =>
  value === 'true' || value === true || value?.toLowerCase?.() === 'true';

function Toggle({
  defaultValue,
  onPlanChange,
  plan,
  planIndex,
  detailIndex,
  id = '',
  detailInnerIndex = -2,
}) {
  const { allPlanFeatures, pricingTable } = useContext(GlobalContext);
  const [state, setState] = useState(false);
  const toggleState = () => {
    setState(!state);
    onPlanChange(plan, planIndex, detailIndex, !state, detailInnerIndex);
  };
  const uniqueId = useMemo(() => {
    if (pricingTable?.length) {
      try {
        const ref = JSON.parse(pricingTable)?.pricing_plans?.[plan]?.plan_id;
        let ref2 = allPlanFeatures[planIndex].details[detailIndex]?.detail_id;
        if (detailInnerIndex > -1) {
          ref2 =
            allPlanFeatures[planIndex].details[detailIndex]?.details[
              detailInnerIndex
            ]?.detail_id || ref2;
        }
        return `${ref}-${ref2}`;
      } catch (err) {
        return id;
      }
    }
  }, [pricingTable]);
  useEffect(() => {
    if (defaultValue == 'false') {
      setState(false);
    } else if (isValueTrue(defaultValue)) {
      setState(true);
    }
  }, [defaultValue]);
  return (
    <div className="flex items-center">
      <button
        id={uniqueId}
        onClick={toggleState}
        className={`w-10 min-w-[40px] h-5 flex items-center shadow-sm p-[3px] rounded-[40px] transition-all duration-200 ${
          state
            ? 'bg-primary-600 dark:bg-primary-500'
            : 'bg-gray-300 dark:bg-gray-600'
        }`}
      >
        <div
          className={`w-[14px] h-[14px] bg-white rounded-full shadow-sm absolute duration-300 ${
            state ? 'translate-x-5' : ' translate-x-0'
          } `}
        />
      </button>
    </div>
  );
}
export default Toggle;
