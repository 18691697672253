import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import useTheme from '@hooks/useTheme';
import { getImageUrl, replaceColorInTheme } from '@utils/index';
import { STRAPI_TOKEN } from '@config/index';
import Image from 'next/image';

function NoCookiePopUp() {
  const router = useRouter();
  const [popUp, setPopUp] = useState(false);
  const [data, setData] = useState<any>();
  const theme = useTheme();

  const acceptAllCookies = async (agreed = true) => {
    const consents = ['activity', 'interaction', 'whereabouts'];
    if (agreed) {
      localStorage.setItem('noCookies', JSON.stringify(consents));
    } else {
      localStorage.removeItem('noCookies');
    }
    setPopUp(false);
  };
  const isWebviewAbout = router.asPath.includes('webview/about');

  const fetchData = async () => {
    let cookiesData: any = {};
    const headers = {
      Authorization: `Bearer ${STRAPI_TOKEN}`,
    };
    await axios
      .get(
        `/api/no-cookies?locale=${(router.query?.language as string) || 'en'}`,
        {
          headers,
        },
      )
      .then((res) => {
        cookiesData = res.data.data.attributes;
      })
      .catch((err) => err);
    setData(cookiesData);
  };
  const init = async () => {
    if (typeof window !== 'undefined') {
      const consents = localStorage.getItem('noCookies');
      if (consents?.length) {
        setPopUp(false);
      } else if (!isWebviewAbout) {
        await fetchData();
        setPopUp(true);
      }
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      init();
    }
  }, []);
  if (!popUp) {
    return null;
  }
  return (
    <div className="w-full sm:w-[446px] left-0 bottom-0 sm:left-2 sm:bottom-1 fixed z-[999999999999999]">
      <div className="w-full hidden sm:flex justify-end relative top-4 -right-4">
        <button
          onClick={() => setPopUp(false)}
          className="rounded-full w-10 h-10 bg-gray-800 dark:bg-gray-600 flex justify-center items-center mt-3"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13L13 1M1 1L13 13"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className="bg-white cookie-shadow sm:pt-10 pb-6 w-full rounded-tr-lg rounded-tl-lg sm:rounded-lg flex flex-col justify-center items-center dark:bg-gray-700 dark:border dark:border-gray-700">
        <div className="relative w-full flex top-4 right-4 sm:hidden justify-end items-center">
          <button
            onClick={() => setPopUp(false)}
            className="rounded-full w-10 h-10 bg-gray-800 dark:bg-gray-600 flex justify-center items-center"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L13 1M1 1L13 13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="px-6 mt-3 sm:mt-0 flex flex-col items-center justify-center w-full">
          <div className="max-w-[82px] mx-auto w-full h-full">
            <Image
              src={
                theme === 'dark'
                  ? getImageUrl(data?.dark_image)
                  : getImageUrl(data?.light_image)
              }
              className="object-cover h-auto w-full"
              alt={data?.title || ''}
              sizes="100vw"
              width={0}
              height={0}
            />
          </div>
          <h1 className="font-inter-hero text-xl leading-8 text-center mt-5 font-semibold text-gray-900 dark:text-white">
            {data?.title}
          </h1>
          <div
            className="font-inter-hero text-center underlined-links mt-3 text-base leading-7 font-normal text-gray-600 dark:text-gray-300 strapi-rich-text-field"
            dangerouslySetInnerHTML={{
              __html: replaceColorInTheme(theme, data?.description || ''),
            }}
          />
          <button
            onClick={() => acceptAllCookies()}
            className="w-full h-12 sm:h-9 flex justify-center items-center font-inter-hero text-[15px] mt-6 font-medium text-white bg-primary-600 shadow-sm rounded-md dark:bg-primary-500 hover:bg-primary-700 dark:hover:bg-primary-700 focus:outline-none transition duration-500 ease-in-out"
          >
            {data?.confirm_button}
          </button>
        </div>
      </div>
    </div>
  );
}
export default NoCookiePopUp;
