const Locales = ['en', 'fr', 'de', 'es', 'it', 'ru', 'pt'];

export const FormattedLocales = [
  { id: 2, display_name: 'English', language_code: 'en' },
  { id: 1, display_name: 'French', language_code: 'fr' },
  { id: 3, display_name: 'German', language_code: 'de' },
  { id: 4, display_name: 'Spanish', language_code: 'es' },
  { id: 5, display_name: 'Italian', language_code: 'it' },
  { id: 6, display_name: 'Russian', language_code: 'ru' },
  { id: 7, display_name: 'Portuguese', language_code: 'pt' },
];

export default Locales;
