export const MEDIA_URL =
  process.env.NEXT_PUBLIC_STRAPI_BASE_URL || process.env.STRAPI_BASE_URL;
export const STRAPI_URL = `${MEDIA_URL}/api`;
export const STRAPI_TOKEN = process.env.STRAPI_MARKETING_TOKEN;
export const SMS_API_URL = process.env.SMS_API_URL || '';
export const QBEND_API_URL = process.env.QBEND_API_URL;
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const HCAPTCHA_SITE_KEY =
  process.env.HCAPTCHA_SITE_KEY || '10000000-ffff-ffff-ffff-000000000001';
export const HCAPTCHA_SECRET_KEY =
  process.env.HCAPTCHA_SITE_KEY || '0x0000000000000000000000000000000000000000';
export const WEBSITE_SECRET = process.env.WEBSITE_SECRET;
export const URL_SECRET = process.env.URL_SECRET;
export const MAIL_PIT_API_URL = process.env.MAIL_PIT_API_URL || '';
export const MAIL_PIT_USER_NAME = process.env.MAIL_PIT_USER_NAME || '';
// export const MAIL_PIT_PASSWORD = process.env.MAIL_PIT_PASSWORD || '';
export const MAIL_PIT_PASSWORD = 'yV!Pz9vBcq';
export const GOOGLE_FONTS_API = process.env.GOOGLE_FONTS_API || '';
export const GOOGLE_FONTS_KEY = process.env.GOOGLE_FONTS_KEY || '';
export const SMS_API_URL_API_KEY = `${process.env.SMS_API_URL}/api_key/v4`;
export const SMS_API_URL_V4 = `${process.env.SMS_API_URL}/v4`;
export const SMS_API_URL_V5 = `${process.env.SMS_API_URL}/v5`;
export const MATOMO_SITE_ID = process.env.MATOMO_SITE_ID || '';
export const MATOMO_URL = (process.env.MATOMO_URL || '').replace(/\/$/, '');
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';
export const INTERCOM_API_BASE = process.env.INTERCOM_API_BASE || '';
export const SMS_API_TOKEN = process.env.SMS_API_TOKEN || '';
export const NODE_ENV = process.env.NODE_ENV || 'development';
export const isProduction = NODE_ENV === 'production';
export const isCyprus = process.env.IS_CYPRESS === 'true';
export const CACHE_CLEAR_THRESHOLD = 24 * 60 * 60 * 1000;

export const headers = {
  Authorization: `Bearer ${STRAPI_TOKEN}`,
};

export const MAX_STRAPI_GET_RETRIES = 2;

export const MAX_SMS_API_GET_RETRIES = 1;

export const LEGAL_TITLE =
  'text-xl leading-7 font-semibold text-gray-900 dark:text-white';

export const LEGAL_DESCRIPTION =
  'text-base leading-7 font-normal text-gray-700 dark:text-gray-300 mt-3 legal-rich-text';

export const plansSortOrder = ['personal', 'cluster', 'team', 'business'];

export const GET_STATIC_PROPS_REVALIDATE = 1;
