import axios, { AxiosRequestConfig } from 'axios';
import { sleep } from '@utils/api';
import { getImageUrl, getMediaType } from '@utils/index';
import { FeaturesEntity } from 'types/strapi/language';
import { ISMSPlan } from 'types/pricing';
import {
  STRAPI_TOKEN,
  STRAPI_URL,
  MAX_STRAPI_GET_RETRIES,
  MAX_SMS_API_GET_RETRIES,
  SMS_API_URL_V4,
  MAIL_PIT_API_URL,
  MAIL_PIT_USER_NAME,
  MAIL_PIT_PASSWORD,
  CACHE_CLEAR_THRESHOLD,
  MEDIA_URL,
  SMS_API_URL,
  plansSortOrder,
} from '.';

const strapiAxios = axios.create({
  baseURL: STRAPI_URL,
  timeout: 9999999,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${STRAPI_TOKEN}`,
  },
});

export const strapiMediaAxios = axios.create({
  baseURL: MEDIA_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${STRAPI_TOKEN}`,
  },
});

const smsAxios = axios.create({
  baseURL: '/api/sms_api',
  timeout: 9999999,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const mailPitAxios = axios.create({
  baseURL: MAIL_PIT_API_URL,
  timeout: 9999999,
  headers: {
    'Content-Type': 'application/json',
  },
  auth: {
    username: MAIL_PIT_USER_NAME,
    password: MAIL_PIT_PASSWORD,
  },
});

export const externalSmsAxios = axios.create({
  baseURL: SMS_API_URL_V4,
  timeout: 9999999,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const externalDefaultSmsAxios = axios.create({
  baseURL: SMS_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const internalAxios = axios.create({
  baseURL: '/api',
  timeout: 9999999,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const externalSmsRequest = async (
  url: string,
  method: string = 'GET',
  data: any = {},
  config: any = {},
) => {
  const resp = await externalSmsAxios.request({
    method,
    url,
    ...(config || {}),
    ...(data ? { data } : {}),
  });
  return resp;
};

export const smsRequestWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
  method: string = 'get',
  config: any = {},
  data: any = {},
) => {
  try {
    const response = await externalSmsRequest(url, method, data, config);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return smsRequestWithRetry(url, retries - 1, delay * 2);
  }
};

export const smsRequest = async (
  url: string,
  method: string = 'GET',
  data: any = {},
  config: any = {},
) => {
  const resp = await smsRequestWithRetry(
    url,
    MAX_SMS_API_GET_RETRIES,
    1000,
    method,
    config,
    data,
  );
  return resp;
};

export const getInternalData = async (url: string) => {
  const resp = await internalAxios.get(url);
  return resp;
};

export const putInternalData = async (url: string, data: any) => {
  const resp = await internalAxios.put(url, data);
  return resp;
};

export const postInternalData = async (url: string, data: any) => {
  const resp = await internalAxios.post(url, data);
  return resp;
};

export const putInternalDataWithRetry: any = async (
  url: string,
  data: any,
  retries: number,
  delay: number = 1000,
) => {
  try {
    const response = await internalAxios.put(url, data);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return putInternalDataWithRetry(url, data, retries - 1, delay * 2);
  }
};

export const postInternalDataWithRetry: any = async (
  url: string,
  data: any,
  retries: number,
  delay: number = 1000,
) => {
  try {
    const response = await internalAxios.post(url, data);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return postInternalDataWithRetry(url, data, retries - 1, delay * 2);
  }
};

export const saveInternalData = async (url: string, data: any) => {
  const resp = await putInternalDataWithRetry(
    url,
    data,
    MAX_STRAPI_GET_RETRIES,
  );

  return resp;
};

export const internalPostCall = async (url: string, data: any) => {
  const resp = await postInternalDataWithRetry(
    url,
    data,
    MAX_STRAPI_GET_RETRIES,
  );

  return resp;
};

export const getInternalDataWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
) => {
  try {
    const response = await internalAxios.get(url);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return getInternalDataWithRetry(url, retries - 1, delay * 2);
  }
};

export const getWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
) => {
  try {
    const response = await strapiAxios.get(url);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return getWithRetry(url, retries - 1, delay * 2);
  }
};

export const smsWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
  method: string = 'get',
  config: any = {},
  data: any = {},
) => {
  try {
    const response = await smsAxios.request({
      method,
      url,
      ...(config || {}),
      ...(data ? { data } : {}),
    });
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return smsWithRetry(url, retries - 1, delay * 2);
  }
};
// const cache = setupCache();

export const getStrapiData = async (url: string) => {
  // const cacheKey = `strapi-${url}`;
  // const cacheData = getCache(cache, cacheKey);
  // if (cacheData) {
  //   try {
  //     const respdotData = JSON.parse(cacheData);
  //     return { data: respdotData };
  //   } catch (e) {
  //     // Empty
  //   }
  // }
  const resp = await getWithRetry(url, MAX_STRAPI_GET_RETRIES);
  // try {
  //   setCache(cache, cacheKey, JSON.stringify(resp.data));
  // } catch (e) {
  //   // Empty
  // }
  return resp;
};

export const sms = async (
  url: string,
  config: any = {},
  method: string = 'GET',
  data: any = {},
) => {
  const resp = await smsWithRetry(
    url,
    MAX_SMS_API_GET_RETRIES,
    1000,
    method,
    config,
    data,
  );
  return resp;
};

export const mailPitWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
) => {
  try {
    const response = await mailPitAxios.get(url);
    return response;
  } catch (err: any) {
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    await sleep(delay + jitter);
    return mailPitWithRetry(url, retries - 1, delay * 2);
  }
};

export const getMailPitData = async (url: string) => {
  const resp = await mailPitWithRetry(url, MAX_STRAPI_GET_RETRIES);
  return resp;
};

export const clearCachedMedia = () => {
  const cachedItems = JSON.parse(
    localStorage.getItem('cachedMediaItems') || '[]',
  );
  cachedItems.forEach((item: keyof Window) => {
    delete window[item];
  });
  localStorage.setItem('cachedMediaItems', JSON.stringify([]));
};

export const shouldClearCache = () => {
  const lastClearTime = localStorage.getItem('lastCacheClearTime');
  if (!lastClearTime) {
    return true;
  }
  const currentTime = Date.now();
  return currentTime - parseInt(lastClearTime, 10) > CACHE_CLEAR_THRESHOLD;
};

export const preLoadImage = async (
  mediaURL: string,
  mediaType: 'image' | 'video',
  callback?: () => any,
) => {
  if (!mediaURL || mediaURL.startsWith('data:')) {
    return mediaURL;
  }
  try {
    if (mediaType === 'image') {
      const img = new window.Image();
      img.src = mediaURL;
    }
    const apiURL = `/media?url=${mediaURL}&type=${mediaType}`;
    const resp = await getInternalDataWithRetry(apiURL);
    if (callback) {
      callback();
    }
    if (resp?.data?.length && resp?.data?.startsWith('data:')) {
      return resp.data;
    }
  } catch (err) {
    return mediaURL;
  }
  return mediaURL;
};

export const convertGetStartedSectionVideosToBase64 = async (
  steps: any,
  theme: any,
) => {
  const tempSteps = await Promise.all(
    steps.map(async (step: any) => {
      const { selectedMedia } = {
        selectedMedia:
          theme === 'light'
            ? getImageUrl(step.media)
            : step?.dark_media?.data?.attributes?.url
              ? getImageUrl(step.dark_media)
              : getImageUrl(step.media),
      };
      if (
        step?.media?.data?.attributes?.url &&
        getMediaType(step.media) === 'video' &&
        theme === 'light'
      ) {
        step.media.data.attributes.url = await preLoadImage(
          selectedMedia,
          'video',
        );
      } else if (
        step?.dark_media?.data?.attributes?.url &&
        getMediaType(step.dark_media) === 'video' &&
        theme === 'dark'
      ) {
        step.dark_media.data.attributes.url = await preLoadImage(
          selectedMedia,
          'video',
        );
      } else if (
        step?.media?.data?.attributes?.url &&
        getMediaType(step.media) === 'video' &&
        theme === 'dark'
      ) {
        step.media.data.attributes.url = await preLoadImage(
          selectedMedia,
          'video',
        );
      }
      return step;
    }),
  );
  return tempSteps;
};

export const convertShowCaseVideosToBase64 = async (
  featuresShowcaseData: any,
  theme: any,
) => {
  const tempShowCaseData = await Promise.all(
    featuresShowcaseData.map(async (feature: FeaturesEntity) => {
      const media =
        theme === 'light'
          ? getImageUrl(feature.light_media)
          : feature.dark_media?.data?.attributes
            ? getImageUrl(feature.dark_media)
            : getImageUrl(feature.light_media);
      const mediaType =
        theme === 'light'
          ? getMediaType(feature.light_media)
          : feature.dark_media?.data?.attributes
            ? getMediaType(feature.dark_media)
            : getMediaType(feature.light_media);
      const mediaTypeObjKeyString =
        theme === 'light'
          ? 'light_media'
          : feature.dark_media?.data?.attributes
            ? 'dark_media'
            : 'light_media';
      const {
        showMediaType,
        mobileMedia,
        selectedMediaType,
        selectedMediaUrl,
        tabletMedia,
        mobileMediaObjKeyString,
        tabletMediaObjKeyString,
      } = {
        selectedMediaType: (mediaType || 'image') as 'image' | 'video',
        selectedMediaUrl: media || '',
        mobileMedia:
          theme === 'light'
            ? getImageUrl(feature.mobile_image)
            : feature.dark_mobile_image?.data?.attributes
              ? getImageUrl(feature.dark_mobile_image)
              : getImageUrl(feature.mobile_image),
        mobileMediaObjKeyString:
          theme === 'light'
            ? 'mobile_image'
            : feature.dark_mobile_image?.data?.attributes
              ? 'dark_mobile_image'
              : 'mobile_image',
        tabletMedia:
          theme === 'light'
            ? getImageUrl(feature.tablet_image)
            : feature.dark_tablet_image?.data?.attributes
              ? getImageUrl(feature.dark_tablet_image)
              : getImageUrl(feature.tablet_image),
        tabletMediaObjKeyString:
          theme === 'light'
            ? 'tablet_image'
            : feature.dark_tablet_image?.data?.attributes
              ? 'dark_tablet_image'
              : 'tablet_image',
        showMediaType: feature.media_layout || 'No Frame',
      };
      const fetchMobileMedia = async () => {
        if (
          mobileMediaObjKeyString === 'mobile_image' &&
          feature?.mobile_image?.data?.attributes?.url &&
          getMediaType(feature.mobile_image) === 'video'
        ) {
          feature.mobile_image.data.attributes.url = await preLoadImage(
            mobileMedia,
            selectedMediaType,
          );
        } else if (
          mobileMediaObjKeyString === 'dark_mobile_image' &&
          feature?.dark_mobile_image?.data?.attributes?.url &&
          getMediaType(feature.dark_mobile_image) === 'video'
        ) {
          feature.dark_mobile_image.data.attributes.url = await preLoadImage(
            mobileMedia,
            selectedMediaType,
          );
        }
      };
      const fetchTableMedia = async () => {
        if (
          tabletMediaObjKeyString === 'tablet_image' &&
          feature?.tablet_image?.data?.attributes?.url &&
          getMediaType(feature.tablet_image) === 'video'
        ) {
          feature.tablet_image.data.attributes.url = await preLoadImage(
            tabletMedia,
            selectedMediaType,
          );
        } else if (
          tabletMediaObjKeyString === 'dark_tablet_image' &&
          feature?.dark_tablet_image?.data?.attributes?.url &&
          getMediaType(feature.dark_tablet_image) === 'video'
        ) {
          feature.dark_tablet_image.data.attributes.url = await preLoadImage(
            tabletMedia,
            selectedMediaType,
          );
        }
      };
      const fetchLaptopMedia = async () => {
        if (
          mediaTypeObjKeyString === 'light_media' &&
          feature?.light_media?.data?.attributes?.url &&
          getMediaType(feature.light_media) === 'video'
        ) {
          feature.light_media.data.attributes.url = await preLoadImage(
            selectedMediaUrl,
            selectedMediaType,
          );
        } else if (
          mediaTypeObjKeyString === 'dark_media' &&
          feature?.dark_media?.data?.attributes?.url &&
          getMediaType(feature.dark_media) === 'video'
        ) {
          feature.dark_media.data.attributes.url = await preLoadImage(
            selectedMediaUrl,
            selectedMediaType,
          );
        }
      };
      if (showMediaType === 'Tablet and Mobile') {
        await fetchMobileMedia();
        await fetchTableMedia();
      } else if (showMediaType === 'Laptop and Mobile') {
        await fetchMobileMedia();
        await fetchLaptopMedia();
      } else if (showMediaType === 'Laptop') {
        await fetchLaptopMedia();
      } else if (showMediaType === 'Tablet') {
        await fetchTableMedia();
      } else {
        await fetchLaptopMedia();
      }
      return feature;
    }),
  );
  return tempShowCaseData;
};

export const convertShowFeaturesVideosToBase64 = async (
  featuredSectionsData: any,
  theme: any,
) => {
  const tempSections = await Promise.all(
    featuredSectionsData.map(async (feature: any) => {
      const {
        dark_media,
        dark_mobile_image,
        dark_tablet_image,
        media,
        media_layout,
        mobile_image,
        tablet_image,
      } = feature;
      const selectedMedia =
        theme === 'light'
          ? {
              media,
              mobile_image,
              tablet_image,
              useMobileAndTablet: media_layout === 'Tablet and Mobile',
              useDesktopAndMobile: media_layout === 'Laptop and Mobile',
              useDesktopOnly: media_layout === 'Laptop',
              useTabletOnly: media_layout === 'Tablet',
            }
          : {
              media: dark_media || media,
              mobile_image: dark_mobile_image || mobile_image,
              tablet_image: dark_tablet_image || tablet_image,
              useMobileAndTablet: media_layout === 'Tablet and Mobile',
              useDesktopAndMobile: media_layout === 'Laptop and Mobile',
              useDesktopOnly: media_layout === 'Laptop',
              useTabletOnly: media_layout === 'Tablet',
            };

      const setMobileImage = async () => {
        if (
          feature?.mobile_image?.data?.attributes?.url &&
          getMediaType(feature.mobile_image) === 'video' &&
          theme === 'light'
        ) {
          feature.mobile_image.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.mobile_image),
            'video',
          );
        } else if (
          feature?.dark_mobile_image?.data?.attributes?.url &&
          getMediaType(feature.dark_mobile_image) === 'video' &&
          theme === 'dark'
        ) {
          feature.dark_mobile_image.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.mobile_image),
            'video',
          );
        }
      };
      const setTabletImage = async () => {
        if (
          feature?.tablet_image?.data?.attributes?.url &&
          getMediaType(feature.tablet_image) === 'video' &&
          theme === 'light'
        ) {
          feature.tablet_image.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.tablet_image),
            'video',
          );
        } else if (
          feature?.dark_tablet_image?.data?.attributes?.url &&
          getMediaType(feature.dark_tablet_image) === 'video' &&
          theme === 'dark'
        ) {
          feature.dark_tablet_image.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.tablet_image),
            'video',
          );
        }
      };
      const setLaptopImage = async () => {
        if (
          feature?.media?.data?.attributes?.url &&
          getMediaType(feature.media) === 'video' &&
          theme === 'light'
        ) {
          feature.media.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.media),
            'video',
          );
        } else if (
          feature?.dark_media?.data?.attributes?.url &&
          getMediaType(feature.dark_media) === 'video' &&
          theme === 'dark'
        ) {
          feature.dark_media.data.attributes.url = await preLoadImage(
            getImageUrl(selectedMedia.media),
            'video',
          );
        }
      };

      if (selectedMedia?.useMobileAndTablet) {
        await setMobileImage();
        await setTabletImage();
      } else if (selectedMedia?.useDesktopAndMobile) {
        await setMobileImage();
        await setLaptopImage();
      } else if (selectedMedia?.useTabletOnly) {
        await setTabletImage();
      } else {
        await setLaptopImage();
      }

      return feature;
    }),
  );

  return tempSections;
};

export const getSMSPlans = async (headers?: AxiosRequestConfig['headers']) => {
  const smsPlansData = externalSmsAxios
    .get('/sms_plans/', {
      ...(headers && { headers }),
    })
    .then((resp) => {
      const arrCopy = resp.data.slice() as ISMSPlan[];
      arrCopy.sort((a: ISMSPlan, b: ISMSPlan) => {
        const aIndex = plansSortOrder.indexOf(a.plan_id);
        const bIndex = plansSortOrder.indexOf(b.plan_id);
        return aIndex - bIndex;
      });
      return arrCopy;
    })
    .catch((err) => {
      throw err;
    });
  return smsPlansData;
};

export const getSMSAddonsList = async (
  headers?: AxiosRequestConfig['headers'],
) => {
  const smsPlansData = externalSmsAxios
    .get('/addons_price_list/', {
      ...(headers && { headers }),
    })
    .then((resp) => {
      const arrCopy = resp.data.slice() as ISMSPlan[];
      arrCopy.sort((a: ISMSPlan, b: ISMSPlan) => {
        const aIndex = plansSortOrder.indexOf(a.plan_id);
        const bIndex = plansSortOrder.indexOf(b.plan_id);
        return aIndex - bIndex;
      });
      return arrCopy;
    })
    .catch((err) => {
      throw err;
    });
  return smsPlansData;
};
