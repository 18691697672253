import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
  FloatingPortal,
} from '@floating-ui/react';
import { GlobalContext } from '@context/globalState';
import { ISMSPlan, PDFLicense } from 'types/pricing';
import { CompareData } from 'types/strapi/language/pricing';
import LICENSE_IDS from '@mocks/license.json';

interface Props {
  defaultValue: string;
  onPlanChange: any;
  plan: number;
  planIndex: number;
  detailIndex: number;
  id: string;
  detailInnerIndex?: number;
  data?: CompareData;
  smsPlans?: ISMSPlan[];
  licensePrices?: PDFLicense[];
}

function AdjustablePriceComponent({
  onPlanChange,
  plan,
  planIndex,
  detailIndex,
  detailInnerIndex,
  data,
  licensePrices = LICENSE_IDS as PDFLicense[],
}: Props) {
  const labelsWithPrices = useMemo(
    () =>
      [
        {
          id: '-1',
          price: 0,
          volume_id: '-1',
          label: 'None',
        },
        ...licensePrices.map((license) => {
          const getLicenseItem = data?.pdf_license_labels.find(
            (item) => item.volume_id === license.volume_id,
          );
          return {
            ...getLicenseItem,
            ...license,
          };
        }),
      ] || [],
    [data, licensePrices],
  );
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<
    (typeof labelsWithPrices)[0] | undefined
  >(undefined);
  useEffect(() => {
    if (!selected) {
      setSelected(labelsWithPrices[1]);
    }
  }, [labelsWithPrices]);
  const { selectedPriceOption, setSelectedPriceOption } =
    useContext(GlobalContext);
  useEffect(() => {
    if (selected?.id === '-1') {
      onPlanChange(plan, planIndex, detailIndex, false, detailInnerIndex);
    }
  }, [selected]);

  useEffect(() => {
    const current = selectedPriceOption[plan as 1 | 2 | 3];
    if (labelsWithPrices?.length && !current?.price?.length) {
      setSelectedPriceOption({
        ...selectedPriceOption,
        [plan as 1 | 2 | 3]: labelsWithPrices[1],
      });
      onPlanChange(plan, planIndex, detailIndex, true, detailInnerIndex);
    }
  }, []);
  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);
  const headingId = useId();
  const id = `floating-dropdown-${plan}-${detailIndex}-${detailInnerIndex}`;
  return (
    <div className="z-9">
      <div
        id={id}
        ref={refs.setReference}
        {...getReferenceProps()}
        className={`px-2.5 py-1.5 shadow-sm border border-gray-300 dark:border-gray-700 min-w-24 rounded-md flex items-center justify-between cursor-pointer ${open ? 'shadow-sm ring-2 ring-offset-2 ring-offset-white dark:ring-offset-gray-600 ring-primary-500 dark:ring-primary-600' : ''}`}
      >
        <p
          id={`selected_${id}`}
          className="font-inter-hero text-[15px] font-medium text-gray-700 dark:text-gray-300"
        >
          {selectedPriceOption?.[plan as 1 | 2 | 3]?.label || selected?.label}
        </p>
        <span
          id={`arrow_${id}`}
          className={`transition-all duration-200 ${open ? 'rotate-180' : ''}`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
      {open && (
        <FloatingPortal>
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              aria-labelledby={headingId}
              {...getFloatingProps()}
              className="w-full px-4 py-2 bg-white dark:bg-gray-700 rounded-md ring-1 ring-black ring-opacity-5 shadow-lg max-w-[224px] z-[99999]"
            >
              {labelsWithPrices.map((option) => (
                <div
                  id={`option_${id}_${option.id}`}
                  onClick={() => {
                    setSelected(option);
                    setSelectedPriceOption({
                      ...selectedPriceOption,
                      [plan as 1 | 2 | 3]: option,
                    });
                    setOpen(false);
                    onPlanChange(
                      plan,
                      planIndex,
                      detailIndex,
                      true,
                      detailInnerIndex,
                    );
                  }}
                  key={option.id}
                  className="flex items-center justify-between cursor-pointer mt-2"
                >
                  <p
                    id={`label_${id}_${option.id}`}
                    className="font-inter-hero text-[15px] font-medium text-gray-700 dark:text-gray-300 text-left whitespace-nowrap leading-5"
                  >
                    {option.label}
                  </p>
                  {(selectedPriceOption?.[plan as 1 | 2 | 3]?.id ||
                    selected?.id) === option.id && (
                    <span
                      id={`selected_icon_${id}_${option.id}`}
                      className="text-primary-500 dark:text-primary-600"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
}

export default AdjustablePriceComponent;
